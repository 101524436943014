<template>
      <div class="container my-5 py-5">
        <div class="tile">
          <div class="card voicemed-card">
            <div class="card-content p-4">
              <h2 class="is-size-4 title">{{ $t("survey.consent.header") }}</h2>
              <br/>
              <p>
                {{ $t("survey.consent.description") }}
                <a
                    href="https://uploads-ssl.webflow.com/5e8c286b1caa596faa8c88d5/5f466d8c7c97441ed5492cd0_VoiceMed_PrivacyPolicy_webapp.pdf"
                >VoiceMed_Privacy_Policy.pdf</a
                >
              </p>
            </div>
          </div>
        </div>

        <section class="my-4 py-4">
          <div class="field">
            <b-checkbox v-model="isConsentGiven">{{
                $t("survey.consent.response")
              }}
            </b-checkbox>
          </div>
        </section>

        <section class="has-text-centered">
          <b-button
              @click.native="triggerNext()"
              size="is-large"
              class="button is-primary"
              :disabled="!isConsentGiven"
          >{{ $t("survey.buttons.continue") }}
          </b-button
          >
        </section>
      </div>
</template>

<script>
export default {
    name : "CovidConsent",
    data: () => ({
        isConsentGiven: false,
    }),
    methods : {
        triggerNext(){
            this.$emit("next")
        }
    }

}
</script>