<template>
  <div>
    <div class="main columns has-text-left">
      <section class="card voicemed-card voicemed-card-centered-medium">
        <div class="card-content ">
          <div class="has-text-centered image-div">
            <img
              src="@/assets/media/gif/complete.gif"
              height="100"
              class="small-img"
              alt=""
            />
          </div>
        </div>
        <div class="card-content">
          <section class="has-text-centered my-4">
            <div class="my-4">
              <h2 class="has-text-primary congrats-text">
                {{ $t("result.everythingFine") }}
              </h2>
              <p>{{ $t("result.thankYou") }}</p>
            </div>

            <button
              @click="logout()"
              class="button is-danger is-rounded is-outlined"
            >
              {{ $t("result.backHome") }}
            </button>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import axios from 'axios';
export default {
  name: "Final",
  data: () => ({
    currentPage: 0,
    isConsentGiven: false
  }),

  methods: {
    moveToPage(num) {
      this.currentPage = num;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace(`/${this.$store.state.locale}`).catch(() => {});
    }
  },
  computed: {
    ...mapState(["locale"])
  }
};
</script>

<style lang="scss" scoped>
.medium-img {
  max-height: 400px;
}

.small-img {
  max-height: 150px;
}

.image-div {
  min-height: 150px;
}

.congrats-text {
  font-weight: bold;
  font-size: 30px;
  color: #a4e064 !important;
}
</style>
