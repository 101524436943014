<template>
  <div class="main-wrapper p-5 bg-sm-voicemed">
    <div clas="container">
      <p
        v-if="showErrorNotice()"
        class="has-text-centered has-text-grey-light mb-5"
      >
        {{ $t("survey.headerErrorNotice") }}
      </p>
    </div>

    <div class="main columns has-text-left" v-if="currentPage == 0">
      <covid-consent @next="moveToPage(1)"></covid-consent>
    </div>

    <div class="main columns has-text-left" v-if="currentPage == 1">
      <div class="container">
        <question-section-v-1
          :questions="questions"
          :itemsPerPage="1"
          :setCurrentPage="currentQuestionPage"
          :questionTranslations="$t('lns.questions', { returnObjects: true })"
          @next="handleQuestionNextPage($event)"
          @previous="handleQuestionPrevPage($event)"
          @end="nextPageSection()"
        ></question-section-v-1>
      </div>
    </div>
    <div class="main columns has-text-centered" v-if="currentPage == 2">
      <div class="container">
        <section class="card voicemed-card voicemed-card-centered-medium">
          <div class="card-content record-info-card">
            <div class="top-content py-4 px-1">
              <h1 class="title voicemed-card-title">
                It’s time to record your voice
              </h1>
              <p>
                Don’t worry we’ll guide you 😉
              </p>
            </div>
            <div class="bottom-content py-4 px-1">
              <h3 class="title voicemed-card-title">
                3 Simple rules
              </h3>
              <ul class="has-text-left voicemed-ul">
                <li>Move to a quite place, <b>without background noise</b></li>
                <li>
                  Check that the microphone is at an arm’s length from your
                  mouth
                </li>
                <li>
                  Avoid sharing personal information during the recording
                  process
                </li>
              </ul>
            </div>
          </div>
          <footer class="card-footer">
            <div class="card-footer-item">
              <button
                class="button is-primary voicemed-button"
                @click="moveToPage(currentPage + 1)"
              >
                I’m ready, let’s go!
              </button>
            </div>
          </footer>
        </section>
      </div>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 3">
      <CoughRecording
        :step="1"
        :stepLength="3"
        :title="$t('survey.cough.question')"
        :instruction="$t('survey.cough.instruction')"
        @output="setAudio($event, 'cough')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'cough')"
      ></CoughRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 4">
      <BreathRecording
        :title="$t('survey.breath.question')"
        :instruction="$t('survey.breath.instruction')"
        @output="setAudio($event, 'breath')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'breath')"
      ></BreathRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 5">
      <SpeechRecording
        :title="$t('survey.speech.question')"
        :instruction="$t('survey.speech.instruction')"
        @output="setAudio($event, 'speech')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'speech')"
      ></SpeechRecording>
    </div>

    <Final v-if="currentPage == 6" />
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Final from "@/views/pages/survey/Final";
import QuestionSectionV1 from "@/core/components/ui/questions-ui//vmd-trial-v1/QuestionSectionV1.vue";
import CoughRecording from "@/core/components/ui/audio-ui/CoughRecording";
import BreathRecording from "@/core/components/ui/audio-ui/BreathRecording";
import SpeechRecording from "@/core/components/ui/audio-ui/SpeechRecording";
import { mapActions, mapGetters } from "vuex";
import surveyService from "@/core/services/survey.service";
import CovidConsent from "../_widgets/CovidConsent.vue";

export default {
  name: "CovidQuestions",
  components: {
    CovidConsent,
    Final,
    QuestionSectionV1,
    CoughRecording,
    BreathRecording,
    SpeechRecording
  },
  created() {
    this.changeSurveyQuestionIfLoggedUserDataExists({ isTrial: false }).then(
      resp => {
        this.questions = resp.questions;
        // this.currentQuestionPage = Number(resp.lastVisitedPage)
        // this.$router.replace({name: "Covid", query: { section: resp, page: 1 } })
      }
    );
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page) || 0;
    }
    if (this.$route.query.section) {
      this.currentQuestionPage = Number(this.$route.query.section) || 0;
    }
    this.questions = this.$t("lns.questions", "en");
  },
  computed: {
    ...mapGetters(["getSurveyQuestions", "getSurveyCompletionPercentage"])
  },
  data: () => ({
    isLoading: false,
    currentPage: 0,
    currentQuestionPage: 0,
    isConsentGiven: false,
    isRecording: false,
    pages: [0, 1, 2, 3, 4, 5, 6],
    needsToComplete: false,
    questions: surveyService.defaultCovidQuestionaires,
    audio: {
      breath: null,
      speech: null,
      cough: null
    }
  }),

  methods: {
    ...mapActions(["postSurvey", "changeSurveyQuestionIfLoggedUserDataExists"]),
    moveToPage(num) {
      this.currentPage = num;
      this.$router
        .replace({
          name: "Covid",
          query: { section: this.currentQuestionPage, page: num }
        })
        .catch(() => {});
      window.scrollTo({
        top: 5,
        behavior: "smooth"
      });
    },
    handleQuestionNextPage($event) {
      this.currentQuestionPage = $event.currentPage;
      this.$router.replace({
        name: "Covid",
        query: { section: this.currentQuestionPage, page: this.currentPage }
      });
      this.submitQuestions();
    },
    handleQuestionPrevPage($event) {
      this.currentQuestionPage = $event.currentPage;
      this.$router.replace({
        name: "Covid",
        query: { section: this.currentQuestionPage, page: this.currentPage }
      });
    },
    submitQuestions() {
      const payload = {
        title: "COVID-19 survery questions",
        description: "COVI19 survey from patients",
        label: "COVID_19",
        questions: this.getSurveyQuestions,
        version: 3.0,
        completion_percentage: this.getSurveyCompletionPercentage,
        last_visited_page: this.currentQuestionPage,
        language: "en"
      };
      surveyService.postSurvey(payload).then(() => {});
    },
    nextPageSection() {
      this.moveToPage(this.currentPage + 1);
      this.submitQuestions();
    },
    async submitResult() {
      try {
        this.isLoading = true;
        await this.submitSample("speech");
        await this.submitQuestions();
        this.isLoading = false;
        this.currentPage = 6;
      } catch (error) {
        this.isLoading = false;
        this.$buefy.dialog.alert("!Ops something went wrong");
      }
    },

    onStream() {
      this.isRecording = true;
    },

    setAudio(value, recordType) {
      this.audio[recordType] = value;
    },

    moveToNextRecord(value, recordType) {
      this.submitSample(recordType).then(() => true);
      this.moveToPage(value);
    },
    submitSample(recordType) {
      return surveyService.postSample(
        "COVID_19",
        recordType,
        this.audio[recordType]
      );
    },

    showErrorNotice() {
      const pageWithoutErrorNotice = [0, 3, 4, 5, 6];
      return !pageWithoutErrorNotice.includes(this.currentPage);
    }
  }
};
</script>
